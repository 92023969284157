@import "../../styles/global.scss";

.notFound {
  @apply flex flex-col items-center justify-center text-center;
  min-height: 100vh;

  .notFound_title {
    font-size: 8rem;
    font-weight: 700;
    line-height: 100%;
    animation: glitch 20s linear infinite;
    
    @keyframes glitch{
      2%,64%{
        transform: translate(2px,0) skew(0deg);
      }
      4%,60%{
        transform: translate(-2px,0) skew(0deg);
      }
      62%{
        transform: translate(0,0) skew(5deg); 
      }
    }
    
    &::before,
    &::after{
      content: attr(title);
      position: absolute;
      left: 0;
    }
    
    &::before{
      animation: glitchTop 3s linear infinite;
      clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    }
    
    @keyframes glitchTop{
      2%,64%{
        transform: translate(2px,-2px);
      }
      4%,60%{
        transform: translate(-2px,2px);
      }
      62%{
        transform: translate(13px,-1px) skew(-13deg); 
      }
    }
    
    &::after{
      animation: glitchBotom 3.5s linear infinite;
      clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
      -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    }
    
    @keyframes glitchBotom{
      2%,64%{
        transform: translate(-2px,0);
      }
      4%,60%{
        transform: translate(-2px,0);
      }
      62%{
        transform: translate(-22px,5px) skew(21deg); 
      }
    }
  }

  .notFound_text {
    font-size: 1.25rem;
  }

  .notFound_link {
    font-weight: 600;
    text-decoration: underline;
  }
}
