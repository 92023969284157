// --! color variables start !--
$white-color: #fff;
$black-color: #000;
$light-color: #FBFBFB;
$primary-color: #445964;
$primary-500-color: rgba(68, 89, 100, 0.5);
$primary-700-color: rgba(68, 89, 100, 0.7);
$primary-dark-color: #263138;
// --! color variables end !--

// --! size variables start !--
$normal-fz: 1rem;
// --! size variables end !--

// --! z-index variables start !--
$navbar-z: 10;
// --! z-index variables end !--